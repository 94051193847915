import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import SocialList from "../components/socialList"

const Contact = () => {
  return (
    <Layout pageName="contact">
      <SEO title="Contact Us" />
      <h1>
        <span>let's keep in touch</span>
      </h1>

      <div className="section">
        <h2>Let Us Know You're Interested</h2>
        <p>
          We're just getting this party started. Want to keep up with our
          progress?
        </p>
        <p>
          Get on our mailing list and you'll be first to hear about our new
          beers, early adopter perks, and opportunities to invest.
        </p>
        <form
          action="https://rotation-beer.us20.list-manage.com/subscribe/post?u=1b62236d48d074708e8fe721e&id=0b823c7eb7"
          method="post"
          id="mc-embedded-subscribe-form"
          name="mc-embedded-subscribe-form"
        >
          <div className="form-row">
            <div className="col-12 col-md-9 mb-2 mb-md-0">
              <input
                type="email"
                name="EMAIL"
                className="form-control form-control-lg"
                placeholder="EMAIL"
              />
            </div>
            <div className="col-12 col-md-9 mb-2 mb-md-0">
              <input
                type="text"
                name="FNAME"
                className="form-control form-control-lg"
                placeholder="FIRST NAME"
              />
            </div>
            <div className="col-12 col-md-9 mb-2 mb-md-0">
              <input
                type="text"
                name="LNAME"
                className="form-control form-control-lg"
                placeholder="LAST NAME"
              />
            </div>
            <div id="mce-responses" className="clear">
              <div
                className="response"
                id="mce-error-response"
                style={{ display: "none" }}
              ></div>
              <div
                className="response"
                id="mce-success-response"
                style={{ display: "none" }}
              ></div>
            </div>

            <div className="col-12 col-md-3">
              <div
                style={{ position: "absolute", left: -5000 }}
                aria-hidden="true"
              >
                <input
                  type="text"
                  name="b_1b62236d48d074708e8fe721e_0b823c7eb7"
                  tabindex="-1"
                  value=""
                />
              </div>

              <button
                type="submit"
                className="send btn btn-block btn-lg btn-primary"
              >
                Sign up!
              </button>
            </div>
          </div>
        </form>
      </div>

      <div className="section">
        <h2>Follow Along</h2>
        <p>We're on all your favorite medias...</p>
        <SocialList />
      </div>
      <div className="section" style={{ marginTop: 50 }}>
        <h2>Reach out anytime </h2>
        <a href="mailto:info@rotation-beer.com">info@rotation-beer.com</a>
      </div>
    </Layout>
  )
}

export default Contact
